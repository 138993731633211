/**
 * Solicitudes Pragma SA
 */

import React, { useContext } from 'react';

import Salute from '@/components/salute';
import { sessionContext } from '@/contexts/session';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import endpoints from '@/services/endpoints';

import Form from './form';
import i18n from './i18n.json';
import styles from './styles.module.scss';
import HelpButton from '@/components/helpButton';
import { phoneNumberPragma } from '@/services/advances/index';

const AdvanceRequest = () => {
  const { state } = useContext(sessionContext);
  const t = useLocalTranslation(i18n as Resource);

  return (
    <div className={styles.container}>
      <HelpButton/>
      <Salute
        msg={t('salute.msg')}
        photo={`${endpoints.GET_MULTIMEDIA}/${state.data?.fotografias[0]?.contenido}`}
        name={`${state.data?.nombres} ${state.data?.apellidos}`}
      />
      <Form />
    </div>
  );
};

export default AdvanceRequest;
