/**
 * Solicitudes Pragma SA
 */

import React, { useEffect, useState, useContext } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import Card from '@/components/card';
import InfiniteScrollList from '@/components/infiniteScroll';
import ReassignModal, { TReassign } from '@/components/reassignModal';
import Button from '@/components/ui/button';
import Modal from '@/components/ui/modal';
import TextField from '@/components/ui/textField';
import { sessionContext } from '@/contexts/session';
import { utilsContext } from '@/contexts/utils';
import useAdvancesData from '@/hooks/useAdvancesData';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import useFormatDate from '@/hooks/useFormatDate';
import useList from '@/hooks/useList';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Apps } from '@/pages';
import { Types, manageAdvance, setFacilitator, getFacilitatorList } from '@/services/advances';
import { TStateLocation } from '@/template';

import i18n from './i18n.json';
import styles from './styles.module.scss';

type TConfimModal = {
  id?: number;
  show: boolean;
};

const Pending = () => {
  const [paramSearched, setParamSearched] = useState('');
  const t = useLocalTranslation(i18n as Resource);
  const {
    state: { data },
    actions: { getNotifications },
  } = useContext(sessionContext);
  const {
    actions: { addToast, setLoading, setScroll },
    state: { scroll },
  } = useContext(utilsContext);
  const cancellablePromise = useCancellablePromise();
  const [list, setList, setListLoading, setVisible, setParam] = useList<Types.TAdvanceItem>();
  const [reassingModal, setReassingModal] = useState<TReassign>({ show: false });
  const [confirmModal, setConfirmModal] = useState<TConfimModal>({ show: false });
  const { cardInfo } = useAdvancesData();
  const formatDate = useFormatDate();
  const navigate = useNavigate();
  const [localScroll, setlocalScroll] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (!(location?.state as TStateLocation)?.previusPath) {
      setScroll(0);
    }
  }, [location]);

  const requestList = (page = 0) => {
    setListLoading(true, !page);
    const form = validatorInput(page);
    cancellablePromise(
      getFacilitatorList(form)
        .finally(() => setListLoading(false))
        .then((res) => setList(res.dato || [], !!page, res.cantidadPaginas, res.ultimaPagina, page))
        .catch((e) => addToast(e.message, 'danger')),
    );
  };

  useEffect(() => {
    if (!paramSearched) {
      requestList();
    }
  }, [paramSearched]);

  useEffect(() => {
    if (!list.lastPage && !list.isAnyVisible) {
      requestList();
    }
  }, [list.isAnyVisible, list.lastPage]);

  const handleFacilitate = (id: number) => {
    setLoading(true);
    setFacilitator(id)
      .finally(() => setLoading(false))
      .then((res) => {
        addToast(res, 'success');
        setParam(
          { id },
          {
            fotografiaUsuarioFacilitador: {
              contenido: data?.fotografias[0]?.contenido || '',
              id: '',
              tipoFotografia: '',
            },
            correoFacilitador: data?.correoEmpresarial || '-',
            nombreUsuarioFacilitador: `${data?.nombres} ${data?.apellidos}`,
          },
        );
        getNotifications(Apps.ADVANCES);
      })
      .catch((e) => addToast(e.message, 'danger'));
  };

  const handleManage = () => {
    if (confirmModal.id) {
      setLoading(true);
      manageAdvance(confirmModal.id)
        .finally(() => setLoading(false))
        .then((res) => {
          addToast(res, 'success');
          setVisible({ id: confirmModal.id as number }, false);
          getNotifications(Apps.ADVANCES);
        })
        .catch((e) => addToast(e.message, 'danger'));
    }
  };

  const validatorInput = (page: number) => {
    const form = {
      numeroPagina: page,
      totalElementos: scroll > 0 ? Math.ceil(scroll / 150) + 2 : 5,
      fechaOrdenAscendente: true,
    };
    if (!paramSearched) {
      return form;
    } else if (isNaN(Number(paramSearched))) {
      return { ...form, nombreUsuario: paramSearched };
    } else {
      return { ...form, idSolicitud: Number(paramSearched) };
    }
  };

  return (
    <>
      <section className={styles['search']}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            requestList();
          }}
        >
          <TextField
            placeholder={t('searcher.placeholder')}
            keyPattern={/^[0-9, a-z, A-Z]*$/}
            onChange={(e) => setParamSearched(e.currentTarget.value)}
            value={paramSearched}
          />
          <Button name="ant-search_button_search" onClick={() => requestList()}>
            {t('actionsSearcher.search')}
          </Button>
        </form>
      </section>
      <InfiniteScrollList list={list} handleNext={requestList} setLocalScroll={setlocalScroll}>
        {list.list.map((item) => (
          <Card
            key={item.id}
            _visible={item._visible}
            header={{
              id: item.id,
              photo: item.fotografiaUsuarioSolicitante?.contenido,
              name: item.nombreUsuario,
              email: item.correoUsuario,
              date: new Date(item.fechaCreacion),
            }}
            data={cardInfo(item)}
            state={
              <div className={styles['state-info']} id={`${item.id}`}>
                <div className={styles['important']}>
                  <span className={styles['title-date']}>
                    {item.tiquetes ? t('state.dateTravel') : t('state.dateHosting')}
                  </span>
                  <span className={styles['date']}>
                    {item.fechaViaje ? formatDate(new Date(`${item.fechaViaje}T00:00`)) : '-'}
                  </span>
                </div>
                <div className={`${styles['important']} ${styles['important-custom']}`}>
                  <span className={styles['title-city']}>{t('state.cityTravel')}</span>
                  <span className={styles['city']}>{item.nombreCiudad || '-'}</span>
                </div>
              </div>
            }
            actions={
              !item.nombreUsuarioFacilitador
                ? [
                    <Button
                      key="ant-facilitate-to-manage_button-assign-me"
                      name="ant-facilitate-to-manage_button-assign-me"
                      onClick={() => handleFacilitate(item.id)}
                    >
                      {t('actions.assignMe')}
                    </Button>,
                    <Button
                      name="ant-facilitate_button-details"
                      key="ant-facilitate_button-details"
                      className={styles['details']}
                      onClick={() => {
                        setScroll(localScroll);
                        navigate('/detallesanticipo', {
                          state: { id: item.id, previusPath: location.pathname },
                        });
                      }}
                      theme="tertiary"
                    >
                      {t('actions.seeDetails')}
                    </Button>,
                  ]
                : item.correoFacilitador === data?.correoEmpresarial
                ? [
                    <Button
                      key="ant-facilitate-to-manage_button-managed"
                      name="ant-facilitate-to-manage_button-managed"
                      onClick={() => setConfirmModal({ id: item.id, show: true })}
                    >
                      {t('actions.managed')}
                    </Button>,
                    <Button
                      key="ant-facilitate-to-manage_button-reassign"
                      name="ant-facilitate-to-manage_button-reassign"
                      onClick={() => setReassingModal({ id: item.id, show: true })}
                    >
                      {t('actions.reassign')}
                    </Button>,
                    <Button
                      name="ant-facilitate_button-supports"
                      key="ant-facilitate_button-supports"
                      className={styles['add']}
                      onClick={() => {
                        setScroll(localScroll);
                        navigate('/facilitar', {
                          state: { id: item.id, previusPath: location.pathname },
                        });
                      }}
                      theme="secondary"
                    >
                      {t('actions.addSupports')}
                    </Button>,
                    <Button
                      name="ant-facilitate_button-details"
                      key="ant-facilitate_button-details"
                      className={styles['details']}
                      onClick={() => {
                        setScroll(localScroll);
                        navigate('/detallesanticipo', {
                          state: { id: item.id, previusPath: location.pathname },
                        });
                      }}
                      theme="tertiary"
                    >
                      {t('actions.seeDetails')}
                    </Button>,
                  ]
                : [
                    <Button
                      name="ant-facilitate_button-details"
                      key="ant-facilitate_button-details"
                      className={styles['details']}
                      onClick={() => {
                        setScroll(localScroll);
                        navigate('/detallesanticipo', {
                          state: { id: item.id, previusPath: location.pathname },
                        });
                      }}
                      theme="tertiary"
                    >
                      {t('actions.seeDetails')}
                    </Button>,
                  ]
            }
          />
        ))}
      </InfiniteScrollList>
      <ReassignModal
        reassign={reassingModal}
        setReassign={setReassingModal}
        setParam={setParam}
        searchable={false}
      />
      <Modal
        title={t('managedModal.title')}
        successLabel={t('managedModal.successLabel')}
        onClose={() => setConfirmModal({ show: false })}
        show={confirmModal.show}
        onSuccess={() => {
          setConfirmModal({ show: false });
          handleManage();
        }}
      >
        {t('managedModal.content', { requestId: confirmModal.id })}
      </Modal>
    </>
  );
};

export default Pending;
