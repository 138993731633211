/**
 * Solicitudes Pragma SA
 */

import React, { useState } from 'react';

import Modal from '@/components/ui/modal';
import TextArea from '@/components/ui/textArea';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';

import i18n from './i18n.json';
import styles from './styles.module.scss';

export type TApprove = {
  id: string | number;
  show: boolean;
};

type TProps = {
  approve: TApprove;
  setApprove: (value: TApprove) => void;
  handleApprove: (id: string | number, reason: string) => void;
  writeRequired?: boolean;
};

const ApproveModal = ({ approve, setApprove, handleApprove, writeRequired = true }: TProps) => {
  const [reason, setReason] = useState('');
  const t = useLocalTranslation(i18n as Resource, 'denyModal');

  return (
    <Modal
      title={t('title')}
      successLabel={t('actions.success')}
      onClose={() => setApprove({ id: approve.id, show: false })}
      show={approve.show}
      onSuccess={() => handleApprove(approve.id, reason)}
      successDisabled={reason.length > 500 || (reason.length < 3 && writeRequired) || writeRequired}
    >
      <TextArea
        data-testid="textarea-reason"
        className={styles['approve']}
        value={reason}
        helper={`${reason.length}/500 ${t('textArea.characters')}`}
        placeholder={t('textArea.placeholder')}
        onChange={(e) => setReason(e.currentTarget.value)}
      />
    </Modal>
  );
};

export default ApproveModal;
