import React, { useRef, useState } from 'react';

import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { SearchIcon } from '@/assets/icons/icons';
import styles from './styles.module.scss';

import i18n from './i18n.json';

const HelpButton = () => {
  const t = useLocalTranslation(i18n as Resource);
  return (
    <>
      <div className={styles['box']}>
        <div className={styles['hlp-buton']}>
          <a
            href={`https://www.pragma.com.co/es/centro-de-ayuda-pragma/mesa-de-ayuda-soporte-administrativo`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <SearchIcon width={35} height={35} />
          </a>
        </div>
        <div className={styles['box__info']}>
          {t('tooltipLink')}
          <div className={styles['box__arrow']}></div>
        </div>
      </div>
    </>
  );
};

export default HelpButton;
