import React from 'react';

type TProps = {
  width: number;
  height: number;
  className?: string;
  fill?: string;
  onclick?: () => void;
};

export const LogoHeaderIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      data-testid="logo-header-icon"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="80 0 62 62"
    >
      <path
        fill="#FFF"
        d="M117.607 37.658c6.498-2.237 11.185-8.416 11.185-15.607a16.32 16.32 0 00-6.818-13.316c.32.054.693.107 1.012.107 2.451 0 4.475-1.97 4.475-4.421 0-2.45-2.024-4.421-4.475-4.421-2.45 0-4.474 1.97-4.474 4.421 0 1.065.373 2.077 1.066 2.876a16.77 16.77 0 00-7.511-1.757c-9.215 0-16.725 7.403-16.725 16.565 0 9.002 7.297 16.352 16.299 16.565-4.368 1.864-7.457 6.179-7.457 11.186 0 6.711 5.486 12.144 12.251 12.144s12.251-5.433 12.251-12.144c.106-6.445-4.794-11.665-11.079-12.198zm-17.045-15.66c0-6.338 5.167-11.452 11.559-11.452 6.391 0 11.558 5.167 11.558 11.452s-5.167 11.505-11.558 11.505c-6.392 0-11.559-5.166-11.559-11.505zm15.979 34.835c-3.941 0-7.137-3.195-7.137-7.084 0-3.888 3.196-7.084 7.137-7.084 3.942 0 7.138 3.196 7.138 7.084 0 3.942-3.196 7.084-7.138 7.084z"
      ></path>
    </svg>
  );
};

export const ChevronDownIcon = ({ width, height, className }: TProps) => (
  <svg
    data-testid="chevron-down-icon"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className={`${className || ''}`}
    fill="none"
    viewBox="0 0 21 12"
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M.634.901a.8.8 0 011.132 0l8.767 8.768L19.301.9a.8.8 0 011.131 1.131L11.1 11.366a.8.8 0 01-1.131 0L.634 2.032a.8.8 0 010-1.13z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export const CancelIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      data-testid="cancel-icon"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 17 17"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 1l14.29 14.29M15.29 1L1 15.29"
      ></path>
    </svg>
  );
};

export const CheckToastTicon = ({ width, height, className }: TProps) => {
  return (
    <svg
      data-testid="check-toast-icon"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 42 42"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M21 7.2C13.379 7.2 7.2 13.379 7.2 21c0 7.622 6.179 13.8 13.8 13.8 7.622 0 13.8-6.178 13.8-13.8 0-7.621-6.178-13.8-13.8-13.8zM6 21c0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M18.45 25.992a1.54 1.54 0 01-1.088-.45l-3.296-3.293a.907.907 0 111.286-1.283l3.099 3.098 8.197-8.197a.906.906 0 011.286 0 .907.907 0 010 1.284l-8.394 8.39c-.29.289-.681.451-1.09.451z"
      ></path>
    </svg>
  );
};

export const CheckTimelineIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 29 23"
    >
      <g filter="url(#filter0_d_331_5440)">
        <path
          fill="#6429CD"
          d="M10.893 18.286a2.115 2.115 0 01-1.497-.62l-4.53-4.526a1.248 1.248 0 111.767-1.764l4.26 4.26L22.16 4.367a1.246 1.246 0 011.768 0 1.247 1.247 0 010 1.764L12.39 17.666a2.125 2.125 0 01-1.497.62z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_331_5440"
          width="27.794"
          height="22.286"
          x="0.5"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_331_5440"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_331_5440" result="shape"></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export const ErrorWarningLineIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 42 42"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M21 7.2C13.379 7.2 7.2 13.379 7.2 21c0 7.622 6.179 13.8 13.8 13.8 7.622 0 13.8-6.178 13.8-13.8 0-7.621-6.178-13.8-13.8-13.8zM6 21c0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M20.86 19.909c.584 0 1.059.488 1.059 1.09v6.11c0 .603-.475 1.091-1.06 1.091-.585 0-1.059-.488-1.059-1.09V21c0-.603.474-1.091 1.06-1.091zM19.8 14.89c0-.602.474-1.09 1.06-1.09h.014c.585 0 1.06.488 1.06 1.09 0 .603-.475 1.092-1.06 1.092h-.015c-.585 0-1.059-.489-1.059-1.091z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const OutlineDownCircleDisableIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 50 50"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M25 2C12.297 2 2 12.297 2 25s10.297 23 23 23 23-10.297 23-23S37.703 2 25 2zM0 25C0 11.193 11.193 0 25 0s25 11.193 25 25-11.193 25-25 25S0 38.807 0 25z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M13.234 19.634a.8.8 0 011.132 0L25 30.27l10.634-10.635a.8.8 0 011.132 1.132l-11.2 11.2a.8.8 0 01-1.132 0l-11.2-11.2a.8.8 0 010-1.132z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const OutlineUpCircleDisableIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
      className={`${className || ''}`}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M12 23.04c6.097 0 11.04-4.943 11.04-11.04C23.04 5.903 18.097.96 12 .96 5.903.96.96 5.903.96 12c0 6.097 4.943 11.04 11.04 11.04zM24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M17.648 14.575a.384.384 0 01-.543 0L12 9.471l-5.104 5.104a.384.384 0 11-.544-.542l5.377-5.377c.15-.15.393-.15.543 0l5.376 5.377c.15.15.15.393 0 .543z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const OutlineWarningCircleIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 42 42"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M21 7.2C13.379 7.2 7.2 13.379 7.2 21c0 7.622 6.179 13.8 13.8 13.8 7.622 0 13.8-6.178 13.8-13.8 0-7.621-6.178-13.8-13.8-13.8zM6 21c0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15z"
        clipRule="evenodd"
      ></path>
      <g fill="#fff" fillRule="evenodd" clipPath="url(#clip0_63_15024)" clipRule="evenodd">
        <path d="M20.097 13.774a1.925 1.925 0 011.806 0c.275.146.506.358.67.613l.002.002 5.674 8.947.004.007c.161.263.246.56.247.864 0 .303-.082.602-.241.866a1.79 1.79 0 01-.666.637 1.923 1.923 0 01-.913.24H15.32a1.921 1.921 0 01-.913-.24 1.79 1.79 0 01-.666-.637 1.664 1.664 0 01-.241-.866c0-.303.086-.601.247-.864l.004-.007 5.674-8.947.43.243-.428-.245c.164-.255.395-.467.67-.613zm.185 1.102l-5.671 8.942a.76.76 0 000 .787c.072.12.177.22.303.29.126.07.268.108.414.11h11.344a.877.877 0 00.414-.11.818.818 0 00.304-.29.76.76 0 000-.787l-5.672-8.941v-.001a.82.82 0 00-.306-.28.879.879 0 00-.824 0 .82.82 0 00-.306.28z"></path>
        <path d="M21 17.412c.276 0 .5.211.5.472v2.531c0 .261-.224.472-.5.472a.487.487 0 01-.5-.472v-2.53c0-.262.224-.473.5-.473zM20.5 22.946c0-.26.224-.472.5-.472h.007c.276 0 .5.211.5.472s-.224.472-.5.472H21a.487.487 0 01-.5-.472z"></path>
      </g>
      <defs>
        <clipPath id="clip0_63_15024">
          <path fill="#fff" d="M0 0H15V14.167H0z" transform="translate(13.5 12.667)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export const BackButtonIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M8.377.91a.533.533 0 00-.754-.754L.156 7.623a.533.533 0 000 .754l7.467 7.467a.533.533 0 10.754-.755L1.821 8.534h13.663A.525.525 0 0016 8a.525.525 0 00-.516-.533H1.821L8.377.91z"
      ></path>
    </svg>
  );
};

export const AlertTooltipFillIcon = ({ width, height, className, onclick }: TProps) => {
  return (
    <svg
      data-testid="alert-tooltip-fill-icon"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      onClick={onclick}
      fill="none"
      viewBox="0 0 24 24"
    >
      <g filter="url(#filter0_i_736_6020)">
        <circle cx="12" cy="12" r="12" fill="#ED0039"></circle>
      </g>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11.278 6.938a1.472 1.472 0 011.98.52l.002.001 4.54 7.579.003.005a1.471 1.471 0 01-1.259 2.208H7.456a1.473 1.473 0 01-1.259-2.208l.004-.005 4.54-7.579.342.206-.341-.208c.13-.216.315-.395.536-.52zm.148.933v.001l-4.537 7.574a.672.672 0 00.574 1.005h9.074a.673.673 0 00.574-1.005l-4.537-7.574a.672.672 0 00-1.148 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M12 10.02c.221 0 .4.178.4.4v2.143a.4.4 0 01-.8 0V10.42c0-.22.18-.4.4-.4zM11.6 14.707c0-.22.18-.4.4-.4h.006a.4.4 0 010 .8H12a.4.4 0 01-.4-.4z"
        clipRule="evenodd"
      ></path>
      <defs>
        <filter
          id="filter0_i_736_6020"
          width="24"
          height="24"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="2.5"></feGaussianBlur>
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.321569 0 0 0 0 0.482353 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_736_6020"></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export const AlertTooltipIcon = ({ width, height, className, fill }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r="12" fill={fill || '#828285'}></circle>
      <path
        fill="#4D4D4F"
        fillRule="evenodd"
        d="M11.278 6.938a1.472 1.472 0 011.98.52l.002.001 4.54 7.579.003.005a1.471 1.471 0 01-1.259 2.208H7.456a1.473 1.473 0 01-1.259-2.208l.004-.005 4.54-7.579.342.206-.341-.208c.13-.216.315-.395.536-.52zm.148.933v.001l-4.537 7.574a.672.672 0 00.574 1.005h9.074a.673.673 0 00.574-1.005l-4.537-7.574a.672.672 0 00-1.148 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#4D4D4F"
        fillRule="evenodd"
        d="M12 10.02c.221 0 .4.178.4.4v2.143a.4.4 0 01-.8 0V10.42c0-.22.18-.4.4-.4zM11.6 14.707c0-.22.18-.4.4-.4h.006a.4.4 0 010 .8H12a.4.4 0 01-.4-.4z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const EyeIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 16 12"
    >
      <path
        fill="#330072"
        d="M15.791 5.254C14.923 4.177 11.83.667 8.008.667 4.184.667 1.09 4.177.222 5.254a.994.994 0 000 1.251c.868 1.078 3.962 4.588 7.785 4.588 3.822 0 6.916-3.51 7.784-4.588a1.043 1.043 0 000-1.25zM8.008 9.703c-3.007 0-5.63-2.815-6.5-3.823C2.36 4.872 5 2.057 8.007 2.057c3.006 0 5.63 2.815 6.498 3.823-.868 1.008-3.492 3.823-6.498 3.823z"
      ></path>
      <path
        fill="#330072"
        d="M8.007 2.613A3.263 3.263 0 004.74 5.88a3.263 3.263 0 003.267 3.267 3.263 3.263 0 003.266-3.267 3.263 3.263 0 00-3.266-3.267zm0 5.143A1.88 1.88 0 016.13 5.88a1.88 1.88 0 011.877-1.877c1.042 0 1.876.834 1.876 1.877a1.869 1.869 0 01-1.876 1.877z"
      ></path>
    </svg>
  );
};

export const DownloadIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M8.533.533a.533.533 0 10-1.066 0v8.668L4.229 5.963a.533.533 0 00-.754.755l4.141 4.141a.532.532 0 00.768 0l4.141-4.141a.533.533 0 10-.754-.755L8.533 9.201V.533z"
      ></path>
      <path
        fill="#fff"
        d="M.533 9.956c.295 0 .534.239.534.533v3.319a1.126 1.126 0 001.126 1.126h11.614a1.126 1.126 0 001.126-1.126v-3.319a.533.533 0 111.067 0v3.319A2.193 2.193 0 0113.807 16H2.193A2.193 2.193 0 010 13.808v-3.319c0-.295.239-.533.533-.533z"
      ></path>
    </svg>
  );
};

export const PlusIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        d="M12.8.8a.8.8 0 00-1.6 0v10.4H.8a.8.8 0 000 1.6h10.4v10.4a.8.8 0 001.6 0V12.8h10.4a.8.8 0 000-1.6H12.8V.8z"
      ></path>
    </svg>
  );
};

export const CancelTreasuryIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        d="M23.766 1.366A.8.8 0 0022.634.234L12 10.87 1.366.234A.8.8 0 00.234 1.366L10.87 12 .234 22.634a.8.8 0 001.132 1.132L12 13.13l10.634 10.635a.8.8 0 001.132-1.132L13.13 12 23.766 1.366z"
      ></path>
    </svg>
  );
};

export const MoneyIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M12 .96C5.903.96.96 5.903.96 12c0 6.097 4.943 11.04 11.04 11.04 6.097 0 11.04-4.943 11.04-11.04C23.04 5.903 18.097.96 12 .96zM0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M14.738 12.152a2.152 2.152 0 00-1.53-.625h-.721v-2.4h1.921a.481.481 0 100-.966h-1.921v-1.44a.483.483 0 00-.821-.34.483.483 0 00-.142.34v1.432h-.72a2.145 2.145 0 00-1.996 1.338 2.15 2.15 0 00-.16.827 2.15 2.15 0 00.626 1.53 2.15 2.15 0 001.53.625h.72v2.4H9.121a.484.484 0 00-.481.482.481.481 0 00.481.481h2.403v1.443a.483.483 0 00.482.481.483.483 0 00.48-.481v-1.443h.722a2.15 2.15 0 001.53-.625 2.15 2.15 0 00.625-1.53 2.18 2.18 0 00-.625-1.529zm-.332 1.53a1.184 1.184 0 01-.35.848 1.193 1.193 0 01-.848.352h-.721v-2.4h.721a1.21 1.21 0 011.108.74c.06.146.091.302.09.46zm-2.882-4.564v2.4h-.72a1.195 1.195 0 01-1.2-1.2 1.184 1.184 0 01.351-.848 1.195 1.195 0 01.848-.352h.721z"
      ></path>
    </svg>
  );
};

export const MinusIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 25 2"
    >
      <path stroke="#fff" strokeLinecap="round" strokeWidth="2" d="M1 1h22.5"></path>
    </svg>
  );
};

export const VerySmileFaceDisabledIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#330072"
        d="M34.765 70C15.598 70 0 54.298 0 35 0 15.702 15.598 0 34.765 0S69.53 15.702 69.53 35c0 19.298-15.598 35-34.765 35zm0-66.017C17.793 3.983 3.983 17.896 3.983 35s13.81 31.017 30.782 31.017S65.547 52.104 65.547 35 51.74 3.983 34.765 3.983z"
      ></path>
      <path
        fill="#330072"
        d="M25.344 36.323a9.384 9.384 0 003.274 7.134 9.37 9.37 0 006.146 2.282 9.338 9.338 0 006.142-2.282 9.397 9.397 0 003.274-7.134c-6.365-2.757-12.639-2.717-18.836 0zM25.345 29.802a1.477 1.477 0 01-1.478-1.477 2.773 2.773 0 00-2.768-2.769 2.773 2.773 0 00-2.768 2.769 1.477 1.477 0 11-2.956 0 5.73 5.73 0 015.724-5.724 5.732 5.732 0 015.724 5.724c0 .816-.662 1.477-1.478 1.477zM52.677 29.802a1.477 1.477 0 01-1.478-1.477 2.773 2.773 0 00-2.768-2.769 2.773 2.773 0 00-2.768 2.769 1.477 1.477 0 11-2.956 0 5.73 5.73 0 015.724-5.724 5.732 5.732 0 015.724 5.724c0 .816-.662 1.477-1.478 1.477z"
      ></path>
    </svg>
  );
};

export const VerySmileFaceActiveIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#00BF11"
        d="M34.75 70c19.193 0 34.752-15.67 34.752-35S53.943 0 34.75 0 0 15.67 0 35s15.559 35 34.75 35z"
      ></path>
      <path
        fill="#231F20"
        d="M24.766 36.398a9.95 9.95 0 003.471 7.564 9.935 9.935 0 006.517 2.42 9.902 9.902 0 006.513-2.42 9.964 9.964 0 003.471-7.564c-6.749-2.923-13.4-2.88-19.972 0zM24.765 29.488a1.566 1.566 0 01-1.567-1.567 2.94 2.94 0 00-2.936-2.935 2.94 2.94 0 00-2.935 2.935 1.566 1.566 0 11-3.134 0c0-3.349 2.725-6.069 6.07-6.069a6.078 6.078 0 016.069 6.07c0 .865-.701 1.566-1.567 1.566zM53.745 29.488a1.566 1.566 0 01-1.567-1.567 2.94 2.94 0 00-2.935-2.935 2.94 2.94 0 00-2.935 2.935 1.566 1.566 0 11-3.134 0c0-3.349 2.724-6.069 6.069-6.069a6.078 6.078 0 016.07 6.07c0 .865-.702 1.566-1.568 1.566z"
      ></path>
    </svg>
  );
};

export const SmileFaceDisabledIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#330072"
        d="M34.765 70C15.595 70 0 54.298 0 35 0 15.702 15.598 0 34.765 0S69.53 15.702 69.53 35c0 19.298-15.598 35-34.765 35zm0-66.017C17.793 3.983 3.983 17.896 3.983 35s13.81 31.017 30.782 31.017S65.547 52.104 65.547 35 51.74 3.983 34.765 3.983z"
      ></path>
      <path
        fill="#330072"
        d="M34.772 43.862c-2.7 0-5.314-.971-7.353-2.732a11.263 11.263 0 01-3.414-5.21 1.848 1.848 0 013.53-1.091 7.596 7.596 0 002.294 3.505 7.565 7.565 0 009.874.004 7.528 7.528 0 002.302-3.505 1.849 1.849 0 012.31-1.223 1.843 1.843 0 011.22 2.31 11.23 11.23 0 01-3.418 5.214 11.218 11.218 0 01-7.345 2.728zM25.35 29.802a1.477 1.477 0 01-1.477-1.478 2.773 2.773 0 00-2.768-2.768 2.773 2.773 0 00-2.769 2.768 1.477 1.477 0 11-2.955 0 5.73 5.73 0 015.724-5.724 5.732 5.732 0 015.723 5.724c0 .817-.66 1.478-1.477 1.478zM52.683 29.802a1.477 1.477 0 01-1.478-1.478 2.773 2.773 0 00-2.768-2.768 2.773 2.773 0 00-2.769 2.768 1.477 1.477 0 11-2.955 0 5.73 5.73 0 015.724-5.724 5.732 5.732 0 015.724 5.724c0 .817-.662 1.478-1.478 1.478z"
      ></path>
    </svg>
  );
};

export const SmileFaceActiveIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#8DD100"
        d="M34.75 70c19.193 0 34.752-15.67 34.752-35S53.943 0 34.75 0 0 15.67 0 35s15.559 35 34.75 35z"
      ></path>
      <path
        fill="#231F20"
        d="M34.753 44.397c-2.864 0-5.634-1.03-7.797-2.897a11.942 11.942 0 01-3.62-5.524 1.96 1.96 0 013.742-1.157 8.053 8.053 0 002.433 3.716 8.022 8.022 0 0010.47.004 7.983 7.983 0 002.442-3.716 1.96 1.96 0 012.45-1.297 1.954 1.954 0 011.292 2.45 11.908 11.908 0 01-3.624 5.528 11.895 11.895 0 01-7.788 2.893zM24.765 29.488a1.566 1.566 0 01-1.567-1.567 2.94 2.94 0 00-2.936-2.935 2.94 2.94 0 00-2.935 2.935 1.566 1.566 0 11-3.134 0c0-3.349 2.725-6.069 6.07-6.069a6.078 6.078 0 016.069 6.07c0 .865-.701 1.566-1.567 1.566zM53.745 29.488a1.566 1.566 0 01-1.567-1.567 2.94 2.94 0 00-2.935-2.935 2.94 2.94 0 00-2.935 2.935 1.566 1.566 0 11-3.134 0c0-3.349 2.724-6.069 6.069-6.069a6.078 6.078 0 016.07 6.07c0 .865-.702 1.566-1.568 1.566z"
      ></path>
    </svg>
  );
};

export const StraightFaceDisabledIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#330072"
        d="M34.765 70C15.595 70 0 54.298 0 35 0 15.702 15.598 0 34.765 0S69.53 15.702 69.53 35c0 19.298-15.598 35-34.765 35zm0-66.017C17.793 3.983 3.983 17.896 3.983 35s13.81 31.017 30.782 31.017S65.547 52.104 65.547 35 51.74 3.983 34.765 3.983z"
      ></path>
      <path
        fill="#330072"
        d="M41.61 39.437H27.932a1.85 1.85 0 01-1.848-1.848 1.85 1.85 0 011.848-1.848H41.61a1.85 1.85 0 011.849 1.848 1.85 1.85 0 01-1.849 1.848zM51.435 29.849a4.246 4.246 0 10-6.005-6.005 4.246 4.246 0 006.005 6.005zM25.313 27.461a4.246 4.246 0 10-8.403-1.226 4.246 4.246 0 008.403 1.226z"
      ></path>
    </svg>
  );
};

export const StraightFaceActiveIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#FFCE00"
        d="M34.75 70c19.193 0 34.752-15.67 34.752-35S53.943 0 34.75 0 0 15.67 0 35s15.559 35 34.75 35z"
      ></path>
      <path
        fill="#231F20"
        d="M42.008 39.705H27.505a1.961 1.961 0 01-1.96-1.96c0-1.081.878-1.96 1.96-1.96h14.503c1.081 0 1.96.879 1.96 1.96s-.879 1.96-1.96 1.96zM52.424 29.538a4.502 4.502 0 10-6.367-6.367 4.502 4.502 0 006.367 6.367zM24.726 27.004a4.502 4.502 0 10-8.91-1.3 4.502 4.502 0 008.91 1.3z"
      ></path>
    </svg>
  );
};

export const SadFaceDisabledIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#330072"
        d="M34.765 70C15.595 70 0 54.298 0 35 0 15.702 15.598 0 34.765 0S69.53 15.702 69.53 35c0 19.298-15.598 35-34.765 35zm0-66.017C17.793 3.983 3.983 17.896 3.983 35s13.81 31.017 30.782 31.017S65.547 52.104 65.547 35 51.737 3.983 34.765 3.983z"
      ></path>
      <path
        fill="#330072"
        d="M40.91 40.15c-.427 0-.857-.147-1.207-.45a7.554 7.554 0 00-4.94-1.832c-1.816 0-3.568.65-4.935 1.832a1.851 1.851 0 01-2.605-.187 1.844 1.844 0 01.188-2.605 11.259 11.259 0 0114.702 0 1.848 1.848 0 01-1.207 3.246l.004-.004zM25.226 27.817a4.246 4.246 0 10-8.267-1.942 4.246 4.246 0 008.267 1.942zM52.556 27.816a4.246 4.246 0 10-8.267-1.942 4.246 4.246 0 008.267 1.942z"
      ></path>
    </svg>
  );
};

export const SadFaceActiveIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#FE9900"
        d="M34.75 70c19.193 0 34.752-15.67 34.752-35S53.943 0 34.75 0 0 15.67 0 35s15.559 35 34.75 35z"
      ></path>
      <path
        fill="#231F20"
        d="M41.266 40.461c-.451 0-.908-.156-1.28-.477a8.01 8.01 0 00-5.236-1.943 7.99 7.99 0 00-5.233 1.943 1.963 1.963 0 01-2.762-.199c-.71-.819-.621-2.052.198-2.762a11.938 11.938 0 0115.589 0 1.959 1.959 0 01-1.28 3.442l.004-.004zM24.64 27.381a4.502 4.502 0 10-8.767-2.06 4.502 4.502 0 008.766 2.06zM53.618 27.384a4.502 4.502 0 10-8.766-2.059 4.502 4.502 0 008.766 2.06z"
      ></path>
    </svg>
  );
};

export const VerySadFaceDisabledIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#330072"
        d="M34.765 70C15.595 70 0 54.298 0 35 0 15.702 15.598 0 34.765 0S69.53 15.702 69.53 35c0 19.298-15.598 35-34.765 35zm0-66.017C17.793 3.983 3.983 17.896 3.983 35s13.81 31.017 30.782 31.017S65.547 52.104 65.547 35 51.737 3.983 34.765 3.983z"
      ></path>
      <path
        fill="#330072"
        d="M25.766 44.511a1.843 1.843 0 01-1.765-2.394 11.262 11.262 0 013.418-5.213 11.219 11.219 0 017.345-2.729c2.7 0 5.313.968 7.353 2.733a11.263 11.263 0 013.414 5.21A1.848 1.848 0 0142 43.209a7.595 7.595 0 00-2.294-3.505 7.568 7.568 0 00-9.878-.004 7.578 7.578 0 00-2.302 3.505 1.847 1.847 0 01-1.765 1.303l.004.003zM25.228 27.817a4.246 4.246 0 10-8.267-1.942 4.246 4.246 0 008.267 1.942zM52.558 27.816a4.246 4.246 0 10-8.267-1.942 4.246 4.246 0 008.267 1.942z"
      ></path>
    </svg>
  );
};

export const VerySadFaceActiveIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        fill="#FF3200"
        d="M34.75 70c19.193 0 34.752-15.67 34.752-35S53.943 0 34.75 0 0 15.67 0 35s15.559 35 34.75 35z"
      ></path>
      <path
        fill="#231F20"
        d="M25.21 45.086c-.19 0-.385-.03-.579-.089a1.954 1.954 0 01-1.292-2.45 11.94 11.94 0 013.623-5.528 11.895 11.895 0 017.789-2.893c2.863 0 5.634 1.026 7.796 2.897a11.942 11.942 0 013.62 5.524 1.96 1.96 0 01-3.742 1.158 8.054 8.054 0 00-2.433-3.717 8.025 8.025 0 00-10.474-.004 8.035 8.035 0 00-2.442 3.716 1.959 1.959 0 01-1.87 1.381l.004.005zM24.64 27.381a4.502 4.502 0 10-8.767-2.06 4.502 4.502 0 008.766 2.06zM53.618 27.384a4.502 4.502 0 10-8.766-2.059 4.502 4.502 0 008.766 2.06z"
      ></path>
    </svg>
  );
};

export const SearchIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      data-testid="search-icon"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#clip0_706_760)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M14.812 15.754a8.963 8.963 0 11.943-.943l4.05 4.05a.667.667 0 01-.943.943l-4.05-4.05zM1.333 8.963a7.63 7.63 0 1113.105 5.314.667.667 0 00-.16.16A7.63 7.63 0 011.333 8.963z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_706_760">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export const ScrollIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      data-testid="scroll-icon"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M9.529 4.195c.26-.26.682-.26.942 0l9.334 9.334a.667.667 0 11-.943.942L10 5.61l-8.862 8.862a.667.667 0 11-.943-.942L9.53 4.195z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const WhatsAppIcon = ({ width, height, className }: TProps) => {
  return (
    <svg
      data-testid="whatsapp-icon"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={`${className || ''}`}
      fill="#fff"
      viewBox="0 0 448 512">
        <path
          d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
        />
    </svg>
  )
}
