/**
 * Solicitudes Pragma SA
 */

import React, { useEffect, useContext, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { AlertTooltipFillIcon, AlertTooltipIcon } from '@/assets/icons/icons';
import Card from '@/components/card';
import InfiniteScrollList from '@/components/infiniteScroll';
import StateLabel from '@/components/stateLabel';
import Button from '@/components/ui/button';
import Modal from '@/components/ui/modal';
import TextArea from '@/components/ui/textArea';
import Tooltip from '@/components/ui/tooltip';
import { utilsContext } from '@/contexts/utils';
import useAdvancesData from '@/hooks/useAdvancesData';
import useCancellablePromise from '@/hooks/useCancellablePromise';
import useList from '@/hooks/useList';
import useLocalTranslation, { Resource } from '@/hooks/useLocalTranslation';
import { Types, getFacilitatorHistoryList, getIncidence } from '@/services/advances';
import { TStateLocation } from '@/template';

import i18n from './i18n.json';
import styles from './styles.module.scss';

const AdvancesHistory = () => {
  const {
    actions: { addToast, setLoading, setScroll },
    state: { scroll },
  } = useContext(utilsContext);
  const cancellablePromise = useCancellablePromise();
  const [list, setList, setListLoading] = useList<Types.TAdvanceItem>();
  const [dataIncidence, setDataIncidence] = useState<Types.TIncidenceFacilitator>();
  const t = useLocalTranslation(i18n as Resource);
  const { cardInfo } = useAdvancesData();
  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState(false);
  const [localScroll, setlocalScroll] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (!(location?.state as TStateLocation)?.previusPath) {
      setScroll(0);
    }
  }, [location]);

  const requestList = (page = 0) => {
    setListLoading(true, !page);
    cancellablePromise(
      getFacilitatorHistoryList({
        numeroPagina: page,
        totalElementos: scroll > 0 ? Math.ceil(scroll / 150) + 2 : 5,
        fechaOrdenAscendente: true,
      })
        .finally(() => setListLoading(false))
        .then((res) => {
          setList(res.dato || [], !!page, res.cantidadPaginas, res.ultimaPagina, page);
        })
        .catch((e) => addToast(e.message, 'danger')),
    );
  };

  const getDataIncidence = async (id: number) => {
    setLoading(true);
    cancellablePromise(
      getIncidence(id)
        .finally(() => {
          setLoading(false);
          setConfirmModal(true);
        })
        .then((res) => {
          setDataIncidence(res);
        })
        .catch((e) => addToast(e.message, 'danger')),
    );
  };

  useEffect(() => {
    requestList();
  }, []);

  return (
    <>
      <div className={styles['refresh']}>
        <Button
          className={styles['button']}
          name="ant-search_button_search"
          onClick={() => requestList()}
        >
          {t('actionsRefresh.refresh')}
        </Button>
      </div>
      <InfiniteScrollList list={list} handleNext={requestList} setLocalScroll={setlocalScroll}>
        {list.list.map((item) => (
          <Card
            key={item.id}
            _visible={item._visible}
            header={{
              id: item.id,
              photo: item.fotografiaUsuarioSolicitante?.contenido,
              name: item.nombreUsuario,
              email: item.correoUsuario,
              date: new Date(item.fechaCreacion),
            }}
            data={cardInfo(item)}
            state={
              <div className={styles['state']}>
                {t('state')}
                <StateLabel state={item.estado} />
                <div className={styles['flag']}>
                  <Button name="ant-card-flag" text>
                    <Tooltip label={t('modal.title')}>
                      {item.tieneIncidencia ? (
                        <AlertTooltipFillIcon
                          width={24}
                          height={24}
                          onclick={() => {
                            item.tieneIncidencia && getDataIncidence(item.id);
                          }}
                        />
                      ) : (
                        <AlertTooltipIcon width={24} height={24} />
                      )}
                    </Tooltip>
                  </Button>
                </div>
              </div>
            }
            actions={[
              <Button
                name="ant-facilitate-history_button-details"
                key="ant-facilitate-history_button-details"
                className={styles['details']}
                onClick={() => {
                  setScroll(localScroll);
                  navigate('/detallesanticipo', {
                    state: { id: item.id, previusPath: location.pathname },
                  });
                }}
                theme="tertiary"
              >
                {t('actions.seeDetails')}
              </Button>,
            ]}
          />
        ))}
      </InfiniteScrollList>
      <Modal
        title={t('modal.title')}
        successLabel={t('modal.successLabel')}
        onClose={() => setConfirmModal(false)}
        show={confirmModal}
        onSuccess={() => {
          setConfirmModal(false);
        }}
      >
        <div className={styles['modal-content']}>
          <div className={styles['modal-header']}>
            <span>{t('modal.content.relatedAreas')}</span>
            <article>
              {t('modal.content.creationDate')}
              <span className={styles['modal-creationDate']}>{dataIncidence?.incidenciaFecha}</span>
            </article>
          </div>
          <ul className={styles['modal-list']}>
            {dataIncidence?.incidenciaHospedaje && <li>{t('incidences.hosting')}</li>}
            {dataIncidence?.incidenciaVuelos && <li>{t('incidences.flights')}</li>}
            {dataIncidence?.incidenciaTransporte && <li>{t('incidences.transportation')}</li>}
            {dataIncidence?.incidenciaRoaming && <li>{t('incidences.roaming')}</li>}
          </ul>
          <TextArea
            label={t('modal.content.description')}
            className={styles['modal-description']}
            value={dataIncidence?.incidenciaObservacion}
            readOnly
          />
        </div>
      </Modal>
    </>
  );
};

export default AdvancesHistory;
